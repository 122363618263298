import React from 'react';
import { IconGP } from 'icons';
import ButtonLinkExternal from 'components/Buttons/ButtonLinkExternal';
import Space from 'components/Space';
import OnlineDoctor from 'assets/online-doctor-image.png';
import Card from '../Card';

export default () => (
  <Card icon={IconGP} title="Online Doctor">
    <img src={OnlineDoctor} alt="Online Doctor" style={{ width: '100%' }} />
    <Space size={20} />
    <ButtonLinkExternal
      option="primary"
      href="http://app.minutemeds.co.uk/avicenna"
      target="__blank"
    >
      Online Doctor
    </ButtonLinkExternal>
  </Card>
);
