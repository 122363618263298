import React from 'react';
import { IconPharmacy } from 'icons';
import Space from 'components/Space';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { Pharmacy, Service } from '@avicennapharmacy/managemymeds-shared';
import { trackEvent } from 'utils/applicationInsights';
import { connect } from 'react-redux';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import Typography from 'components/Typography';
import Card from '../Card';

type AviClinicCardProps = {
  pharmacy: Pharmacy | null;
  setSelectedService: (service: Service | null) => void;
};

const AviClinicCard = ({ pharmacy, setSelectedService }: AviClinicCardProps) => {
  const { hasAviClinicCard } = pharmacy || {};

  if (!hasAviClinicCard) {
    return null;
  }

  return (
    <Card icon={IconPharmacy} title="Travel Clinic">
      <Typography fontStyle="body">Book an appointment for a travel service provided by your pharmacy</Typography>
      <Space size={20} />
      <ButtonLink
        option="primary"
        to={Routes.PHARMACY_SERVICES}
        onClick={() => {
          setSelectedService(null);
          trackEvent('ViewAviClinicServices');
        }}
      >
        Book now
      </ButtonLink>
    </Card>
  );
};

export default connect(null, {
  setSelectedService: pharmacyServicesActions.setSelectedService,
})(AviClinicCard);
