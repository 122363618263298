import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import {
  Input, DetailsWrapper, DetailWrapper, ErrorWrapper,
} from 'components/Form';
import {
  getConfigVar,
  accountStatuses,
  emailSchema,
  Account,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import Button from 'components/Buttons/Button';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import Typography from 'components/Typography';
import Space from 'components/Space';
import useStateWithLocalStorage from 'hooks/useStateWithLocalStorage';
import { RouteChildrenProps } from 'react-router-dom';
import PanelMessage from 'components/PanelMessage';
import { storageKeys, storageType } from 'constants/registration';
import * as userActions from 'redux/actions/user';
import * as alertActions from 'redux/actions/alert';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import { CustomError } from 'types/common';
import { emailIsValid } from 'utils/validation';
import { toast } from 'react-toastify';
import { AlertProps } from 'redux/actions/alert';
import Layout from '../components/Layout';
import {
  ButtonWrapper, StyledForm,
} from '../components/Shared';

type FormValues = {
  email: string;
};

type VerifyEmailStateProps = {
  invalidVerificationToken?: boolean;
  email?: string;
};

type VerifyEmailProps = {
  emailUniquenessCheck: (email: string) => void;
  resetEmailUniquenessCheck: () => void;
  updateAccountDetails: (account: Account) => void;
  createAccount: (email: string, marketingOption: boolean) => void;
  setAlert: (props: AlertProps) => void;
  marketingEnabled: boolean;
  account: Account;
  uniquenessChecking: boolean;
  uniquenessChecked: boolean;
  isValidEmail: boolean;
  error: CustomError | null;
  accountStatus: string;
} & RouteChildrenProps;

const VerifyEmail = ({
  emailUniquenessCheck,
  resetEmailUniquenessCheck,
  updateAccountDetails,
  createAccount,
  setAlert,
  location,
  marketingEnabled,
  account,
  uniquenessChecking,
  uniquenessChecked,
  isValidEmail,
  accountStatus,
  error,
}: VerifyEmailProps) => {
  const { state }: { state?: VerifyEmailStateProps | null } = location;
  const [infoMessage, setInfoMessage] = useState('');
  const [verifyError, setVerifyError] = useState(!!state?.invalidVerificationToken);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [showResendEmailButton, setShowResendEmailButton] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [buttonText, setButtonText] = useState('Continue');
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [, setEmailValue] = useStateWithLocalStorage(storageKeys.EMAIL, storageType.DEFAULT);
  const [, setAccountIdValue] = useStateWithLocalStorage(storageKeys.ACCOUNT_ID, storageType.DEFAULT);

  const resendVerificationEmail = async (email: string) => {
    // Allow a user with an unverified account to send another verification email.
    try {
      await Axios.get(`${getConfigVar('resendVerificationEndpoint')}/${email}`);
      setInfoMessage(
        'A verification email has been sent to your email inbox with a link to verify'
        + ' your account. Please open it to continue with registration.',
      );
      setShowResendEmailButton(false);
      trackEvent('ResendVerificationEmail');
    } catch (e) {
      setAlert({
        message: 'There was an error, please try again or contact help & support.',
        alertType: toast.TYPE.ERROR,
        autoClose: 10000,
        position: toast.POSITION.TOP_CENTER,
        navigationObject: null,
      });
      // setErrors({ email: 'There was an error, please try again or contact help & support.' });
      trackEvent('ResendVerificationEmailError', { e });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (uniquenessChecking) {
      setButtonText('Checking');
    } else if (loading) {
      setButtonText('Sending');
    } else {
      setButtonText('Continue');
    }
  }, [uniquenessChecking, loading]);

  useEffect(() => {
    if (state?.email) {
      updateAccountDetails({ ...account, email: state?.email });
      emailUniquenessCheck(state?.email);
    }
  }, []);

  useEffect(() => {
    if (isSubmitting && uniquenessChecked) {
      try {
        setLoading(true);
        setAccountIdValue(account.id);
        if (!emailIsValid(account.email) || !isValidEmail) {
          // If an account exists and it is created or verfified, resend verification email.
          if (account.accountStatus && account.accountStatus !== accountStatuses.ACTIVE) {
            resendVerificationEmail(account.email);
            trackEvent(
              'SendVerificationEmailError', { reason: `Account already exists and status is ${account.accountStatus}` },
            );
            setIsSubmitting(false);
            resetEmailUniquenessCheck();
            return;
          }

          if (account.accountStatus === accountStatuses.ACTIVE) {
            // Otherwise if an account exists and it is active, prompt the user to login.
            setInfoMessage(
              'An account already exists with this email address. If this is your account'
              + ' then please click Login below.',
            );
            setLoginEmail(account.email);
            setShowLoginButton(true);
            trackEvent('SendVerificationEmailError', { reason: 'Account already exists and is verified' });
            setLoading(false);
            setIsSubmitting(false);
            resetEmailUniquenessCheck();
            return;
          }
        } else if (isValidEmail && !accountStatus) {
          createAccount(account?.email, marketingEnabled);
          setInfoMessage(
            'A verification email has been sent to your email inbox with a link to verify'
            + ' your account. Please open it to continue with registration.',
          );
          setLoading(false);
          setIsSubmitting(false);
          resetEmailUniquenessCheck();
          return;
        }
      } catch (e) {
        setAlert({
          message: 'There was an error, please try again or contact help & support.',
          alertType: toast.TYPE.ERROR,
          autoClose: 10000,
          position: toast.POSITION.TOP_CENTER,
          navigationObject: null,
        });
        setLoading(false);
        setIsSubmitting(false);
        resetEmailUniquenessCheck();
        trackEvent('SendVerificationEmailError', { e });
      }
    }
    setLoading(false);
  }, [accountStatus, account, isSubmitting]);

  return (
    <Layout>
      <Space size={60} />
      <Formik
        enableReinitialize
        initialValues={{ email: state?.email || '' }}
        validationSchema={emailSchema}
        onSubmit={async ({ email }: FormValues) => {
          setLoading(true);
          setVerifyError(false);
          trackEvent('SetDateOfBirthInStorage');
          trackEvent('SetEmailInStorage');
          setEmailValue(email);
          if (emailIsValid(account.email)) {
            emailUniquenessCheck(account.email);
            setIsSubmitting(true);
          }
        }}
      >
        {({
          values, handleChange,
        }) => (
          <>
            <Typography fontStyle="h2" margin>
              Your Details
            </Typography>
            <Space size={30} />
            {verifyError && !infoMessage && (
              <PanelMessage
                type="error"
                message="Your email address could not be verified. Please try again later. If it continues, please contact us."
              />
            )}
            <StyledForm aria-label="email-form">
              <DetailsWrapper>
                <DetailWrapper>
                  <Input
                    label="Email"
                    id="email"
                    name="email"
                    placeholder="john@email.com"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShowLoginButton(false);
                      setShowResendEmailButton(false);
                      setInfoMessage('');
                      handleChange(e);
                      updateAccountDetails({ ...account, email: e.target.value });
                    }}
                    value={values.email}
                    disabled={loading}
                  />
                </DetailWrapper>
                <ErrorMessage name="email">{(message) => <ErrorWrapper message={message} />}</ErrorMessage>
                {error?.message && (
                  <PanelMessage
                    type="error"
                    message={error?.message}
                  />
                )}
                {infoMessage && (
                  <Typography fontStyle="bodyBold" margin>
                    {infoMessage}
                  </Typography>
                )}
              </DetailsWrapper>
              <Space />
              <ButtonWrapper>
                {showLoginButton && (
                  <ButtonLink option="secondary" to={{ pathname: Routes.LOGIN, state: { email: loginEmail } }}>
                    Continue
                  </ButtonLink>
                )}
                {!showLoginButton && !showResendEmailButton && !infoMessage && (
                  <Button
                    option="primary"
                    type="submit"
                    loading={loading}
                    disabled={loading || !emailIsValid(values.email) || uniquenessChecking}
                  >
                    {buttonText}
                  </Button>
                )}
              </ButtonWrapper>
            </StyledForm>
          </>
        )}
      </Formik>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  account: state.user.account,
  marketingEnabled: state.user.marketingEnabled,
  uniquenessChecking: state.user.uniquenessChecking,
  uniquenessChecked: state.user.uniquenesChecked,
  isValidEmail: state.user.isValidEmail,
  accountStatus: state.user.account.accountStatus,
  error: state.user.error,
});

export default connect(mapState, {
  emailUniquenessCheck: userActions.emailUniquenessCheck,
  resetEmailUniquenessCheck: userActions.resetEmailUniquenessCheck,
  updateAccountDetails: userActions.updateAccountDetails,
  createAccount: userActions.createAccount,
  setAlert: alertActions.setAlert,
})(VerifyEmail);
