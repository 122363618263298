import {
  Action,
  Service,
  Booking,
  ServiceNamesLookup,
} from '@avicennapharmacy/managemymeds-shared';
import { pharmacyServicesActionTypes } from 'redux/actions/pharmacyServices';

export type pharmacyServicesStateType = {
  servicesLoading: boolean;
  servicesFetched: boolean;
  services: Service[];
  selectedService: Service | null;
  selectedSubService: string | null;
  selectedDate: Date | null;
  bookingsLoading: boolean;
  bookingsFetched: boolean;
  bookings: Booking[];
  serviceNamesLookup: ServiceNamesLookup | null;
};

const initialPharmacyServicesState: pharmacyServicesStateType = {
  servicesLoading: false,
  servicesFetched: false,
  services: [],
  selectedService: null,
  selectedSubService: null,
  selectedDate: null,
  bookingsLoading: false,
  bookingsFetched: false,
  bookings: [],
  serviceNamesLookup: null,
};

export default (state = initialPharmacyServicesState, action: Action): pharmacyServicesStateType => {
  switch (action.type) {
    case pharmacyServicesActionTypes.FETCH_BOOKINGS:
      return { ...state, bookingsLoading: true };
    case pharmacyServicesActionTypes.FETCHED_BOOKINGS:
      return {
        ...state, bookingsLoading: false, bookingsFetched: true, bookings: action.payload,
      };
    case pharmacyServicesActionTypes.FETCH_SERVICES:
      return { ...state, servicesLoading: true };
    case pharmacyServicesActionTypes.FETCHED_SERVICES:
      return {
        ...state, servicesLoading: false, servicesFetched: true, services: action.payload,
      };
    case pharmacyServicesActionTypes.SET_SELECTED_SERVICE:
      return { ...state, selectedService: action.payload };
    case pharmacyServicesActionTypes.SET_SELECTED_SUBSERVICE:
      return { ...state, selectedSubService: action.payload };
    case pharmacyServicesActionTypes.CANCELED_BOOKING:
      return { ...state, bookings: state.bookings.filter(({ id }) => id !== action.payload) };
    case pharmacyServicesActionTypes.ON_BOOKED:
      return { ...state, bookings: [...state.bookings, action.payload] };
    case pharmacyServicesActionTypes.FETCH_SERVICE_NAMES:
      return {
        ...state,
        serviceNamesLookup: action.payload.serviceNamesLookup,
      };
    default:
      return state;
  }
};
