import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import DateBox from 'components/DateBox';
import ButtonLink from 'components/Buttons/ButtonLink';
import Card from 'components/Cards/Card';
import Typography from 'components/Typography';
import * as reminderActions from 'redux/actions/reminders';
import * as appointmentActions from 'redux/actions/appointments';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import {
  MedicationReminder,
  naturalSort,
  ReorderReminder,
  Order,
  Appointment,
  Booking,
  Service,
  Patient,
  isIM1Enabled,
} from '@avicennapharmacy/managemymeds-shared';
import styled from 'styled-components';
import Routes from 'routes';
import { trackEvent } from 'utils/applicationInsights';
import Divider from 'components/Divider';
import { avicennaTheme } from 'components/GlobalStyle';
import { isToday, compareDesc, format } from 'date-fns';
import { IconReorderPill, IconCalendar } from 'icons';
import Space from 'components/Space';
import Flex from 'typescript-styled-flex';
import {
  selectPharmacyServiceBookingEnabled,
  selectAppointmentsEnabled,
  isReorderRemindersDisabled,
  isDailyRemindersDisabled,
  isRemindersDisabled,
} from 'redux/selectors';
import formatTimespan from 'utils/timespan';

const Section = styled.div`
  margin: 0 10px;
`;

const IconWrapper = styled.span`
  width: 50px;
  margin-right: 24px;
`;

type ScheduleCardProps = {
  remindersFetched: boolean;
  remindersLoading: boolean;
  medicationReminders: MedicationReminder[];
  remindersDisabled: boolean;
  reorderReminders: ReorderReminder[];
  reorderRemindersDisabled: boolean;
  dailyRemindersDisabled: boolean;
  orders: Order[];
  isIM1Registered: boolean;
  allowManualReordering: boolean;
  appointmentsEnabled: boolean;
  appointments: Appointment[];
  appointmentsFetched: boolean;
  appointmentsLoading: boolean;
  serviceBookingEnabled: boolean;
  bookings: Booking[];
  bookingsFetched: boolean;
  bookingsLoading: boolean;
  patient: Patient;
  fetchReminders: () => void;
  fetchAppointments: () => void;
  fetchBookings: (patientId: string, pharmacyId: string) => void;
  setSelectedService: (service: Service | null) => void;
};

const ScheduleCard = ({
  remindersFetched,
  remindersLoading,
  medicationReminders,
  reorderReminders,
  remindersDisabled,
  reorderRemindersDisabled,
  dailyRemindersDisabled,
  isIM1Registered,
  allowManualReordering,
  fetchReminders,
  appointmentsEnabled,
  appointments,
  appointmentsFetched,
  appointmentsLoading,
  fetchAppointments,
  serviceBookingEnabled,
  bookings,
  bookingsFetched,
  bookingsLoading,
  patient,
  fetchBookings,
  setSelectedService,
}: ScheduleCardProps) => {
  const todaysDate = new Date().toISOString();

  useEffect(() => {
    if (!remindersFetched && !remindersLoading) {
      fetchReminders();
    }
    if (!appointmentsFetched && isIM1Registered && appointmentsEnabled) {
      fetchAppointments();
    }

    if (!bookingsFetched && serviceBookingEnabled) {
      fetchBookings(patient?.id, patient?.pharmacyId);
    }
  }, [
    remindersFetched,
    remindersLoading,
    appointmentsFetched,
    fetchAppointments,
    isIM1Registered,
    fetchBookings,
    bookingsFetched,
    serviceBookingEnabled,
    appointmentsEnabled,
    fetchReminders,
  ]);

  function getReordersForToday() {
    return reorderReminders
      ?.sort((a, b) => compareDesc(new Date(a.reminderDt), new Date(b.reminderDt)))
      .reverse()
      .filter(({ reminderDt }: { reminderDt: string }) => isToday(new Date(reminderDt)));
  }

  const reorderRemindersForToday = getReordersForToday();
  const hasMedicalReminders = medicationReminders?.length > 0 ?? false;
  const bookingsForToday = bookings?.filter((b) => isToday(new Date(b.start)));
  const appointmentsForToday = appointments?.filter((a) => isToday(new Date(a.startTime)));
  const isManualPatient = patient?.id?.length > 0 && !patient.iM1Available;

  if (!patient?.iM1Available && appointmentsForToday?.length + bookingsForToday.length === 0) {
    return null;
  }

  return (
    <Card
      headerComponent={<DateBox date={todaysDate} />}
      title="Your schedule for today"
      loading={!(
        !remindersLoading
        || (!isIM1Registered || !appointmentsLoading)
        || !bookingsLoading
      )}
    >
      {remindersFetched && isIM1Enabled() && !isManualPatient && (
        <>
          <>
            <Section>
              {!dailyRemindersDisabled && (
                <>
                  {hasMedicalReminders && (
                    <>
                      {naturalSort(medicationReminders, 'reminderTime').slice(0, 3).map(({ id, reminderTime, description }) => (
                        <div key={id}>
                          <Flex alignCenter>
                            <Typography fontStyle="dateNumber">{reminderTime.slice(0, 5)}</Typography>
                            <Space size={20} horizontal />
                            <Typography fontStyle="body">{description}</Typography>
                          </Flex>
                          <Divider color={avicennaTheme.colors.primaryLight} />
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </Section>
          </>

          {(reorderRemindersForToday?.length > 0 && !reorderRemindersDisabled) && (
            <>
              <Section>
                {reorderRemindersForToday.slice(0, 3).map((reminder) => (
                  <div key={reminder.id}>
                    <Space />
                    <Flex>
                      <IconWrapper>
                        <IconReorderPill width={50} height={50} />
                      </IconWrapper>
                      <div>
                        <Typography fontStyle="h3">Reorder reminder!</Typography>
                        <Typography fontStyle="body">{reminder?.description}</Typography>
                      </div>
                    </Flex>
                    {(isIM1Registered || allowManualReordering) && (
                      <>
                        <Space />
                        <ButtonLink
                          margin={false}
                          option="primary"
                          to={allowManualReordering ? {
                            pathname: Routes.NEW_ORDER,
                            state: { manualItems: [reminder] },
                          } : {
                            pathname: Routes.NEW_ORDER,
                            state: { selectedItemCourseGuid: reminder?.courseGuid },
                          }}
                          onClick={() => trackEvent('ScheduleCardReorderMedicine')}
                        >
                          Reorder this medicine
                        </ButtonLink>
                      </>
                    )}
                  </div>
                ))}
              </Section>
            </>
          )}

          {!remindersDisabled && (
            <>
              {((medicationReminders === undefined || medicationReminders === null || medicationReminders?.length === 0)
                && (reorderReminders === undefined || reorderReminders === null || reorderRemindersForToday?.length === 0))
                && (
                  <>
                    <Typography fontStyle="body">You have no reminders today.</Typography>
                  </>
                )}

              <Space />

              {(reorderRemindersForToday?.length > 0 || medicationReminders?.length > 0) && (
                <>
                  <Divider />
                  <Space size={10} />
                </>
              )}

              <ButtonLink
                data-testid="home-reminder-button"
                margin={false}
                option={(reorderRemindersForToday?.length > 0 || medicationReminders?.length > 0) ? 'secondary' : 'primary'}
                to={Routes.REMINDERS}
                onClick={() => trackEvent('ScheduleCardViewDailyReminders')}
              >
                {(reorderRemindersForToday?.length > 0 || medicationReminders?.length > 0) ? 'Go to reminders' : 'Create a reminder'}
              </ButtonLink>
              <Space size={10} />
            </>
          )}

          {!remindersDisabled && (
            <>
              <Divider />
            </>
          )}
        </>
      )}

      {appointmentsEnabled && (
        <>
          <Section>
            {appointmentsForToday.length > 0 ? appointmentsForToday.map((appointment) => (
              <div key={appointment.slotId}>
                <Space />
                <Flex>
                  <IconWrapper>
                    <IconCalendar width={50} height={50} />
                  </IconWrapper>
                  <div>
                    <Typography fontStyle="h3">Practice appointment</Typography>
                    <Typography fontStyle="body">
                      {formatTimespan(appointment.startTime, appointment.endTime, true)}
                    </Typography>
                  </div>
                </Flex>
                <Space />
              </div>
            )) : (
              <>
                <Typography fontStyle="body">You have no Practice appointments today.</Typography>
                <Space />
              </>
            )}
            <ButtonLink
              margin={false}
              option={appointments.length > 0 ? 'secondary' : 'primary'}
              to={Routes.APPOINTMENTS}
              onClick={() => trackEvent('ScheduleCardViewAppointments')}
            >
              {appointments.length > 0 ? 'Go to appointments' : 'Book an appointment'}
            </ButtonLink>
            <Space size={20} />
          </Section>
        </>
      )}

      {serviceBookingEnabled && (
        <>
          {(appointmentsEnabled || (remindersFetched && isIM1Enabled() && !isManualPatient)) && <Divider />}
          <Section>
            {bookingsForToday.length > 0 ? bookingsForToday.map(({
              id, start, end, name,
            }) => (
              <div key={id}>
                <Space />
                <Flex>
                  <IconWrapper>
                    <IconCalendar width={50} height={50} />
                  </IconWrapper>
                  <div>
                    <Typography fontStyle="h3">{name} appointment</Typography>
                    <Typography fontStyle="body">
                      {format(new Date(start), 'HH:mm')} - {format(new Date(end), 'HH:mm')}
                    </Typography>
                  </div>
                </Flex>
                <Space />
              </div>
            )) : (
              <>
                <Typography fontStyle="body">You have no Pharmacy appointments today.</Typography>
                <Space />
              </>
            )}
            <ButtonLink
              margin={false}
              option={appointments.length > 0 ? 'secondary' : 'primary'}
              to={Routes.PHARMACY_SERVICES}
              onClick={() => {
                setSelectedService(null);
                trackEvent('ScheduleCardViewPharmacyServices');
              }}
            >
              {appointments.length > 0 ? 'Go to pharmacy services' : 'Book a pharmacy service'}
            </ButtonLink>
            <Space size={20} />
          </Section>
        </>
      )}
    </Card>
  );
};

const mapState = (state: GlobalState) => ({
  remindersFetched: state.reminders?.fetched,
  remindersLoading: state.reminders?.loading,
  medicationReminders: state.reminders?.medicationReminders?.filter((r) => r.patientId === state.user.patient.id),
  reorderReminders: state.reminders?.reorderReminders?.filter((r) => r.patientId === state.user.patient.id),
  orders: state.orders.currentOrders,
  isIM1Registered: !!state.user.patient.patientIntegration,
  allowManualReordering: state.user.patient.allowManualReordering,
  appointmentsEnabled: selectAppointmentsEnabled(state),
  appointments: state.appointments.appointments,
  appointmentsFetched: state.appointments.appointmentsFetched,
  appointmentsLoading: state.appointments.appointmentsLoading,
  serviceBookingEnabled: selectPharmacyServiceBookingEnabled(state),
  bookings: state.pharmacyServices.bookings,
  bookingsFetched: state.pharmacyServices.bookingsFetched,
  bookingsLoading: state.pharmacyServices.bookingsLoading,
  remindersDisabled: isRemindersDisabled(state),
  reorderRemindersDisabled: isReorderRemindersDisabled(state),
  dailyRemindersDisabled: isDailyRemindersDisabled(state),
  patient: state.user.patient,
});

export default connect(mapState, {
  fetchReminders: reminderActions.fetchReminders,
  fetchAppointments: appointmentActions.fetchAppointments,
  fetchBookings: pharmacyServicesActions.fetchBookings,
  setSelectedService: pharmacyServicesActions.setSelectedService,
})(ScheduleCard);
