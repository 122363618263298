import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Layout from 'components/Layout';
import { GlobalState } from 'redux/reducers';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import {
  Service, formatDate, getConfigVar, GetPharmaciesAPIResponse, Patient, Booking, BookingPlatform,
} from '@avicennapharmacy/managemymeds-shared';
import Axios from 'axios';
import { avicennaTheme } from 'components/GlobalStyle';
import Divider from 'components/Divider';
import Button from 'components/Buttons/Button';
import { alertFunctions, AlertFunctionProps } from 'redux/actions/alert';
import Typography from 'components/Typography';
import ButtonLinkExternal from 'components/Buttons/ButtonLinkExternal';
import { trackEvent } from 'utils/applicationInsights';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { Input } from 'components/Form';
import Space from 'components/Space';
import { IconFind } from 'icons';
import { selectPharmacyServiceBookingEnabled } from 'redux/selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import Book from './components/Book';

const BookingWrapper = styled.div`
  border: 2px solid ${(props) => props.theme.colors.primaryLight};
  border-radius: 8px;
  padding: 8px 16px;
`;

const BookingItem = styled.div`
  width: 100%;
  border-bottom: 2px solid ${(props) => props.theme.colors.primaryLight};
  padding: 20px 0;
  margin-bottom: 20px;
`;

const BookingTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Column = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;

  ${(props) => props.theme.breakpoints.mobileTablet} {
    width: calc(50% - 75px);
    margin-right: 150px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const BookingList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const VCLinkTextWrapper = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: 'center';
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FullWidthButtonLinkExternal = styled(ButtonLinkExternal)`
  flex: 1;
  width: 100%;
`;

const FullWidthButton = styled(Button)`
  flex: 1;
  width: 100%;
`;

const VCPasswordContainer = styled.div`
  position: relative;
  flex: 1;
  margin-right: 8px;

  span {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s linear, opacity 0.5s linear;
    position: absolute;
    top: -50%;
    right: -50%;
    background: ${(props) => props.theme.colors.primaryDark};
    color: ${(props) => props.theme.colors.white};
    padding: 12px;
    border-radius: 8px;
    font-size: 18px;
  }

  &:hover > span {
    visibility: visible;
    opacity: 1;
  }
`;

const IconWrapper = styled.span`
  width: 36px;
  margin-left: 24px;
  padding-top: 12px;
  color: ${(props) => props.theme.colors.primary};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const FullWidthText = styled(Typography)`
  flex: 1;
`;

type VCPasswordProps = {
  value: string;
  id: string;
};
const VCPassword = ({ value }: VCPasswordProps) => {
  const [showing, setShowing] = useState(false);
  const [copied, setCopied] = useState(false);
  return (
    <VCPasswordContainer>
      {showing ? (
        <>
          <Input
            contentEditable={false}
            type="text"
            value={value}
            onClick={(e) => {
              // https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
              e.currentTarget.select();
              e.currentTarget.setSelectionRange(0, 9999);
              document.execCommand('copy');
              setCopied(true);
              setTimeout(() => setCopied(false), 3000);
            }}
          />
          <span>{copied ? 'Copied to clipboard' : `Copy ${value} to clipboard`}</span>
        </>
      ) : (
        <FullWidthButton option="secondary" onClick={() => setShowing(true)}>
          Show password
        </FullWidthButton>
      )}
    </VCPasswordContainer>
  );
};

type PharmacyServicesProps = {
  patient: Patient;
  bookingsLoading: boolean;
  servicesLoading: boolean;
  servicesFetched: boolean;
  services: Service[];
  bookingsFetched: boolean;
  bookings: Booking[];
  serviceBookingEnabled: boolean;
  pharmacyName: string;
  fetchBookings: (patientId: string, pharmacyId: string) => void;
  fetchServices: (pharmacyId: string) => void;
  canceledBooking: (id: string) => void;
} & AlertFunctionProps;

const PharmacyServices = ({
  patient,
  bookingsLoading,
  servicesLoading,
  servicesFetched,
  bookingsFetched,
  bookings,
  serviceBookingEnabled,
  pharmacyName,
  fetchBookings,
  fetchServices,
  canceledBooking,
  hideAlert,
  showErrorAlert,
  showSuccessAlert,
}: PharmacyServicesProps) => {
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (!bookingsFetched && serviceBookingEnabled) {
      fetchBookings(patient?.id, patient?.pharmacyId);
    }
  }, [bookingsFetched, fetchBookings]);

  useEffect(() => {
    if (!servicesFetched && patient?.pharmacyId) {
      fetchServices(patient.pharmacyId);
    }
  }, [servicesFetched, fetchServices, patient]);

  const cancelBooking = async (booking: Booking) => {
    setDeleting(true);
    try {
      hideAlert();
      await Axios.delete(getConfigVar('directCancelBookingEndpoint'), {
        data: {
          resourceId: booking.resourceId,
          startDate: booking.start,
          cancellationKey: booking.cancellationKey,
          cancellationReason: '',
          bookingPlatform: BookingPlatform.WebApp,
        },
      });
      trackEvent('CancelPharmacyService', { start: booking.start, serviceId: booking.serviceId });
      showSuccessAlert('Your service has been cancelled.');
      canceledBooking(booking.id);
    } catch (error) {
      trackEvent('CancelPharmacyServiceError', { error });
      if (error.response.status === 422) {
        showErrorAlert(error.response.data.message);
      } else {
        showErrorAlert('Unable to cancel service at this time. Please try again.');
      }
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Layout>
      <Typography fontStyle="h1" margin>
        Pharmacy services
      </Typography>
      {serviceBookingEnabled ? (
        <>
          <Column>
            <BookingWrapper>
              <Typography fontStyle="h2" margin>
                My booked services
              </Typography>
              <Typography fontStyle="body">{`Your nominated pharmacy is ${pharmacyName}`}</Typography>
              {bookingsLoading ? (
                <>
                  <LoadingSpinner name="bookings" center />
                  <Typography fontStyle="body" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    loading bookings
                  </Typography>
                </>
              ) : (
                <>
                  <Typography fontStyle="body">
                    {bookings.length > 0
                      ? `You have ${bookings.length} booked service${bookings.length === 1 ? '' : 's'}`
                      : 'Available services at this pharmacy can be booked here'}
                  </Typography>
                  <Divider color={avicennaTheme.colors.primaryLight} />
                  <BookingList>
                    {bookings.length === 0 && (
                      <Typography fontStyle="bodyBold">You don&apos;t have any appointments booked.</Typography>
                    )}
                    {bookings.map((booking: Booking) => (
                      <BookingItem key={booking.id}>
                        <BookingTitle>
                          <Typography fontStyle="bodyBigBold">{booking?.name}{booking?.serviceSubType ? `: ${booking?.serviceSubType}` : ''}</Typography>
                          <Button
                            option="remove"
                            onClick={() => {
                              if (window.confirm('Are you sure you wish to cancel this booking?')) {
                                cancelBooking(booking);
                              }
                            }}
                            loading={deleting}
                            disabled={deleting}
                          >
                            Cancel
                          </Button>
                        </BookingTitle>
                        <Typography fontStyle="h3">
                          {`${formatDate(booking?.start, 'longDate')} - ${formatDate(booking?.start, 'time12Hour')}`}
                        </Typography>
                        {Boolean(booking?.locationInformation) && (
                          <Row>
                            <FullWidthText fontStyle="body">
                              {booking?.locationInformation}
                            </FullWidthText>
                            {!booking?.vcJoinLink && (
                              <IconWrapper onClick={async () => {
                                try {
                                  const pharmacies = (
                                    await Axios.get<GetPharmaciesAPIResponse>(getConfigVar('getPharmaciesEndpoint'))
                                  ).data;

                                  const pharmacy = pharmacies.find((p) => p.id === booking?.pharmacyId);

                                  if (!pharmacy?.longitude || !pharmacy?.latitude) {
                                    throw Error('Missing fields on pharmacy');
                                  }
                                  window.open(`https://maps.google.com?q=${pharmacy.latitude},${pharmacy.longitude}`);
                                } catch (e) {
                                  console.warn(e);
                                  showErrorAlert('There was a problem opening the map.');
                                }
                              }}
                              >
                                <IconFind />
                              </IconWrapper>
                            )}
                          </Row>
                        )}
                        {Boolean(booking?.vcJoinLink) && (
                          <>
                            <Space />
                            <Row>
                              {Boolean(booking?.vcPassword) && (
                                <VCPassword value={booking?.vcPassword!} id={booking?.id} />
                              )}
                              <FullWidthButtonLinkExternal option="secondary" href={booking?.vcJoinLink!} target="_blank">
                                <VCLinkTextWrapper>Open video link</VCLinkTextWrapper>
                                <FontAwesomeIcon icon={faVideo} />
                              </FullWidthButtonLinkExternal>
                            </Row>
                          </>
                        )}
                      </BookingItem>
                    ))}
                  </BookingList>
                </>
              )}
            </BookingWrapper>
          </Column>
          <Column>
            <Typography fontStyle="h2" margin>
              Book a new service
            </Typography>
            {servicesLoading ? (
              <>
                <Space size={20} />
                <LoadingSpinner name="services" center />
                <Typography fontStyle="body" style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  loading services
                </Typography>
              </>
            ) : (
              <Book />
            )}
          </Column>
        </>
      ) : (
        <>
          <p>Your pharmacy is not currently accepting bookings. Please check back soon.</p>
        </>
      )}
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state?.user.patient,
  bookingsLoading: state.pharmacyServices.bookingsLoading,
  servicesLoading: state.pharmacyServices.servicesLoading,
  servicesFetched: state.pharmacyServices.servicesFetched,
  services: state.pharmacyServices.services,
  bookingsFetched: state.pharmacyServices.bookingsFetched,
  bookings: state.pharmacyServices.bookings,
  serviceBookingEnabled: selectPharmacyServiceBookingEnabled(state),
  pharmacyName: state.user.pharmacy.businessName,
});

export default connect(mapState, {
  fetchServices: pharmacyServicesActions.fetchServices,
  fetchBookings: pharmacyServicesActions.fetchBookings,
  canceledBooking: pharmacyServicesActions.canceledBooking,
  ...alertFunctions,
})(PharmacyServices);
