import { ServiceNamesLookup } from '@avicennapharmacy/managemymeds-shared';

export const serviceIdMap = (
  serviceNameId: string,
  serviceNamesLookup: ServiceNamesLookup,
) => {
  let serviceName = '';
  if (serviceNameId) [serviceName] = serviceNamesLookup[serviceNameId].name;

  return serviceName.length ? serviceName : '';
};

export const subServiceIdMap = (
  serviceNameId: string,
  subServiceNameId: string | null,
  serviceNamesLookup: ServiceNamesLookup,
) => {
  let subServiceName = '';
  if (subServiceNameId) subServiceName = serviceNamesLookup[serviceNameId].subs[subServiceNameId].name;

  return subServiceName.length ? subServiceName : '';
};
