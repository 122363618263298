import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from 'components/Layout';
import { GlobalState } from 'redux/reducers';
import MultiPatientSwitcher from 'components/MultiPatientSwitcher';
import BookPharmacyServiceCard from 'components/Cards/BookPharmacyServiceCard';
import MessagesCard from 'components/Cards/Messages';
import MedicalRecordCard from 'components/Cards/MedicalRecordCard';
import CardsContainer from 'components/Containers/CardsContainer';
import Space from 'components/Space';
import BookGPAppointmentCard from 'components/Cards/BookGPAppointmentCard';
import {
  COVID19_VAC_AND_BOOSTER_SERVICE,
  getConfigVar,
  isIM1Enabled,
  Pharmacy,
  Service,
  Patient,
  ServiceNamesLookup,
} from '@avicennapharmacy/managemymeds-shared';
import {
  selectMedicalRecordEnabled, selectMessagesEnabled, selectPharmacyServiceBookingEnabled, selectAppointmentsEnabled,
} from 'redux/selectors';
import PromotedService from 'components/Cards/PromotedService';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import { RouteChildrenProps } from 'react-router-dom';
import Routes from 'routes';
import PromotionCard from 'components/Cards/PromotionCard';
import IM1Reminder from 'components/IM1Reminder';
import AviClinicCard from 'components/Cards/AviClinicCard';

type MyServicesProps = {
  setSelectedService: (service: Service | null) => void;
  setSelectedSubService: (subService: string | null) => void;
  fetchServices: (pharmacyId: string) => void;
  loading: boolean;
  firstName: string;
  isIM1Registered: boolean;
  messagesEnabled: boolean;
  medicalRecordEnabled: boolean;
  serviceBookingEnabled: boolean;
  appointmentsEnabled: boolean;
  pharmacy: Pharmacy | null;
  patient: Patient;
  serviceNamesLookup: ServiceNamesLookup | null;
  services: Service[];
};

const MyServices = ({
  setSelectedService,
  setSelectedSubService,
  fetchServices,
  loading,
  isIM1Registered,
  messagesEnabled,
  medicalRecordEnabled,
  serviceBookingEnabled,
  appointmentsEnabled,
  pharmacy,
  patient,
  serviceNamesLookup,
  history,
  services,
}: MyServicesProps & RouteChildrenProps) => {
  useEffect(() => {
    if (pharmacy?.id) {
      fetchServices(pharmacy?.id!);
    }
  }, [pharmacy]);

  return (
    <Layout loading={loading} greenBackground>
      <MultiPatientSwitcher />
      <Space size={20} />
      {isIM1Enabled() && !patient?.iM1Available && patient?.id?.length > 0 && <IM1Reminder />}
      <CardsContainer aria-label="My services cards">
        {serviceBookingEnabled && <BookPharmacyServiceCard />}
        {appointmentsEnabled && isIM1Registered && isIM1Enabled() && <BookGPAppointmentCard />}
        {isIM1Registered && messagesEnabled && <MessagesCard />}
        {getConfigVar('featureCovidVaccination').toLowerCase() === 'true' && pharmacy?.ownershipType === 'Owned' && (
          <PromotionCard
            onClick={() => {
              setSelectedService(COVID19_VAC_AND_BOOSTER_SERVICE);
              setSelectedSubService(null);
              history.push(
                Routes.PHARMACY_SERVICES,
              );
            }}
            showPromotion
            promoteServiceId={COVID19_VAC_AND_BOOSTER_SERVICE.id}
            promoteServiceCardText={COVID19_VAC_AND_BOOSTER_SERVICE.description}
            serviceNamesLookup={serviceNamesLookup}
            services={services}
          />
        )}
        {isIM1Registered && medicalRecordEnabled && isIM1Enabled() && <MedicalRecordCard />}
        <PromotedService
          pharmacy={pharmacy}
          onClick={(service: Service) => {
            setSelectedService(service);
            history.push(
              Routes.PHARMACY_SERVICES,
            );
          }}
          serviceNamesLookup={serviceNamesLookup}
          services={services}
        />
        <AviClinicCard pharmacy={pharmacy} />
      </CardsContainer>
    </Layout>
  );
};

const mapState = (state: GlobalState) => ({
  loading: state.user.userDetailsLoading,
  isIM1Registered: !!state.user.patient.patientIntegration,
  messagesEnabled: selectMessagesEnabled(state),
  serviceBookingEnabled: selectPharmacyServiceBookingEnabled(state),
  medicalRecordEnabled: selectMedicalRecordEnabled(state),
  appointmentsEnabled: selectAppointmentsEnabled(state),
  pharmacy: state.user.pharmacy,
  patient: state.user.patient,
  serviceNamesLookup: state.pharmacyServices.serviceNamesLookup,
  services: state.pharmacyServices.services,
});

export default connect(mapState, {
  setSelectedService: pharmacyServicesActions.setSelectedService,
  setSelectedSubService: pharmacyServicesActions.setSelectedSubService,
  fetchServices: pharmacyServicesActions.fetchServices,
})(MyServices);
