import React, { useState, useEffect } from 'react';
import { IconMyServices } from 'icons';
import Typography from 'components/Typography';
import ButtonLink from 'components/Buttons/ButtonLink';
import Routes from 'routes';
import Space from 'components/Space';
import { trackEvent } from 'utils/applicationInsights';
import {
  Service,
  COVID19_VAC_AND_BOOSTER_SERVICE,
  ServiceNamesLookup,
} from '@avicennapharmacy/managemymeds-shared';
import Card from '../Card';

type PromotionCardProps = {
  onClick: (s: Service) => void;
  services: Service[];
  showPromotion: boolean;
  promoteServiceId?: string;
  promoteServiceCardText?: string;
  serviceNamesLookup: ServiceNamesLookup | null;
};
const PromotionCard = ({
  onClick,
  showPromotion,
  promoteServiceId,
  promoteServiceCardText,
  serviceNamesLookup,
  services,
}: PromotionCardProps) => {
  const [service, setService] = useState<Service | undefined>(undefined);

  useEffect(() => {
    if (showPromotion && promoteServiceId === COVID19_VAC_AND_BOOSTER_SERVICE.id) setService(COVID19_VAC_AND_BOOSTER_SERVICE);
  }, [promoteServiceId, showPromotion]);

  useEffect(() => {
    if (promoteServiceId !== COVID19_VAC_AND_BOOSTER_SERVICE.id) {
      const promotedService = services.find((s) => s.id === promoteServiceId);
      setService(promotedService);
    }
  }, [services]);

  if (!showPromotion || !service) {
    return null;
  }

  return (
    <>
      <Card
        icon={IconMyServices}
        title={
          service?.serviceNameId && serviceNamesLookup
            ? serviceNamesLookup[service?.serviceNameId].name[0]
            : service?.name
        }
      >
        <Typography fontStyle="body">{promoteServiceCardText}</Typography>
        <Space size={20} />
        <ButtonLink
          option="primary"
          to={Routes.PHARMACY_SERVICES}
          onClick={() => {
            onClick(service);
            trackEvent('ViewPromotedService');
          }}
        >
          Book now
        </ButtonLink>
      </Card>
    </>
  );
};

export default PromotionCard;
