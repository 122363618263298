import React from 'react';
import {
  Service,
  Pharmacy,
  PromotedServices,
  ServiceNamesLookup,
} from '@avicennapharmacy/managemymeds-shared';
import PromotionCard from '../PromotionCard';

type PromotedServicesProps = {
  onClick: (s: Service) => void;
  pharmacy: Pharmacy | null;
  serviceNamesLookup: ServiceNamesLookup | null;
  services: Service[];
};

const PromotedService = ({
  onClick,
  pharmacy,
  serviceNamesLookup,
  services,
}: PromotedServicesProps) => {
  const { hasPromoteServiceCard, promotedServices } = pharmacy || {};

  if (!hasPromoteServiceCard || !promotedServices) {
    return null;
  }

  return (
    <>
      {promotedServices?.map((promotedService: PromotedServices) => {
        const showPromotion = Boolean(
          hasPromoteServiceCard && promotedService?.serviceId && promotedService?.serviceCardText,
        );

        return (
          <PromotionCard
            onClick={onClick}
            showPromotion={showPromotion}
            promoteServiceId={promotedService.serviceId}
            promoteServiceCardText={promotedService.serviceCardText}
            serviceNamesLookup={serviceNamesLookup}
            services={services}
          />
        );
      })}
    </>
  );
};

export default PromotedService;
