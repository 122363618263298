import React, { useEffect } from 'react';
import { Service, Patient, ServiceNamesLookup } from '@avicennapharmacy/managemymeds-shared';
import { connect } from 'react-redux';
import { GlobalState } from 'redux/reducers';
import * as pharmacyServicesActions from 'redux/actions/pharmacyServices';
import { Select } from 'components/Form';
import { Option } from 'react-select/src/filters';
import { serviceIdMap, subServiceIdMap } from 'utils/service';

type ServiceListProps = {
  patient: Patient;
  services: Service[];
  fetched: boolean;
  selectedService: Service | null;
  selectedSubService: string | null;
  selectedServiceId: string | undefined;
  serviceNamesLookup: ServiceNamesLookup | null;
  fetchServices: (pharmacyId: string) => void;
  setSelectedService: (service: Service | null) => void;
  setSelectedSubService: (subService: string | null) => void;
};

const ServiceList = ({
  patient,
  services,
  selectedService,
  selectedSubService,
  selectedServiceId,
  fetched,
  serviceNamesLookup,
  fetchServices,
  setSelectedService,
  setSelectedSubService,
}: ServiceListProps) => {
  useEffect(() => {
    if (!fetched) {
      fetchServices(patient.pharmacyId);
    }
  }, [fetched, fetchServices]);

  const options = services.map((s) => ({
    value: s.id,
    label: serviceIdMap(
      s?.serviceNameId!,
      serviceNamesLookup!,
    ),
    data: s,
  }));
  const subOptions = selectedService?.subServices?.map((st) => ({
    value: st.serviceNameId,
    label: subServiceIdMap(selectedService?.serviceNameId!, st.serviceNameId, serviceNamesLookup!),
  }));
  return (
    <>
      <Select
        label="Which service would you like to book?"
        options={options}
        value={options.find(({ value }) => selectedServiceId === value)}
        onChange={(val: Option) => {
          setSelectedService(val.data);
          setSelectedSubService(null);
        }}
      />
      {selectedService?.hasServiceSubTypes && (
        <Select
          key={`selectedSubService_${selectedSubService}`}
          label={`Select a ${serviceIdMap(
            selectedService?.serviceNameId!,
            serviceNamesLookup!,
          )} type`}
          options={subOptions}
          value={subOptions?.find(({ value }) => selectedSubService === value)}
          onChange={({ value }: { value: string; label: string }) => {
            setSelectedSubService(value);
          }}
        />
      )}
    </>
  );
};

const mapState = (state: GlobalState) => ({
  patient: state.user.patient,
  services: state.pharmacyServices.services,
  fetched: state.pharmacyServices.servicesFetched,
  selectedServiceId: state.pharmacyServices.selectedService?.id,
  selectedService: state.pharmacyServices?.selectedService,
  selectedSubService: state.pharmacyServices?.selectedSubService,
  serviceNamesLookup: state.pharmacyServices.serviceNamesLookup,
});

export default connect(
  mapState,
  {
    fetchServices: pharmacyServicesActions.fetchServices,
    setSelectedService: pharmacyServicesActions.setSelectedService,
    setSelectedSubService: pharmacyServicesActions.setSelectedSubService,
  },
)(ServiceList);
